@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.fastsearch {
  float:left; 
  background: #212121;
  height: 300px;
  overflow: scroll;
  position: fixed;
  padding-right: 10px;
  z-index: 100000;
}

.gridselect {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}    
.gridselect select {
  font-family: 'Arial';
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 5px 5px;
  outline: 0;
  border: 0px solid #000000;
  border-radius: 0px;
  background: #252526;
  color: #f2f0f0;
  appearance: none;
  -webkit-appearance: none;
-moz-appearance: none;
}
.gridselect select::-ms-expand {
    display: none;
}
.gridselect select:hover,
.gridselect select:focus {
    color: #f2f0f0;
    background: #252526;
}
.gridselect select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.udsselect {
  position: relative;
  display: inline-block;
  width: 100%;
}    
.udsselect select {
  font-family: 'Arial';
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 5px 5px;
  outline: 0;
  border: 0px solid #000000;
  border-radius: 0px;
  background: #252526;
  color: #cc7d00;
  font-weight: bold;
  appearance: none;
  -webkit-appearance: none;
-moz-appearance: none;
}
.udsselect select::-ms-expand {
    display: none;
}
.udsselect select:hover,
.udsselect select:focus {
    color: #ffae00;
    background: #252526;
}
.udsselect select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.fastsearch-inner {
  margin: 5px;
}
.fastsearch-inner:hover {
  background: #555;
  color:darkorange;
  margin: 5px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}


::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}